import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const Privacy = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h2 className='title'>
                 Politique de Confidentialités
                </h2>
                <small>Dernère mise à jour : 12 Janvier 2024</small>
              </div>
            </div>
          </div> <br></br>
            <div className='col-lg-12'>
  <div className='single-service-inner'>
    <div className='details'>
      <div>
        <h5>
          Introduction
        </h5>
        <p>Lorsque vous utilisez notre application ou site web de gestion immobilière, vous nous faites confiance pour traiter vos données. Ces politiques de confidentialité ont pour but de vous informer sur les informations que nous collectons, dans quel but et comment nous les utilisons. Ces règles sont importantes, et nous espérons que vous prendrez le temps de les lire attentivement.</p>
      </div>
      <div className="mt-5">
        <h5>
          COLLECTE DE DONNÉES PERSONNELLES
        </h5>
        <p>Nous collectons les informations suivantes : <br/>
          <FaCheckCircle/> Nom et prénom <br/>
          <FaCheckCircle/> Adresse e-mail <br/>
          <FaCheckCircle/> Numéro de téléphone <br/>
          <FaCheckCircle/> Adresse physique <br/>
          <FaCheckCircle/> Informations financières liées à la gestion immobilière. <br/>
          Ces données personnelles sont collectées uniquement pour assurer le bon fonctionnement de notre site internet/application mobile de gestion immobilière et ne sont pas transmises à des tiers.</p>
      </div>
      <div className="mt-5">
        <h5>
          FICHIERS JOURNAUX
        </h5>
        <p>Lorsque vous utilisez notre site Web/application mobile de gestion immobilière, nous collectons et stockons des informations dans les fichiers journaux de nos serveurs. Ceci comprend : <br/><br/>
          <FaCheckCircle/> Comment vous avez utilisé le service de gestion immobilière, comme vos recherches de biens immobiliers. <br/>
          <FaCheckCircle/> Votre adresse IP. <br/>
          <FaCheckCircle/> Données relatives aux événements liés à l'appareil que vous utilisez, tels que les interactions avec les annonces immobilières, l'activité du système, les paramètres matériels, le type et la langue du navigateur, la date et l'heure de la demande et l'URL de référence. <br/>
          Ces données personnelles sont collectées uniquement pour assurer le bon fonctionnement du site internet/application mobile de gestion immobilière et ne sont pas transmises à des tiers.</p>
                </div>
                <div className="mt-5">
        <h5>
          FORMULAIRES ET INTERACTIVITÉ
        </h5>
        <p>Nous pouvons également collecter des informations via des formulaires interactifs sur notre site/application mobile de gestion immobilière. Ces données sont utilisées dans le but de faciliter la communication et la gestion des biens immobiliers.</p>
      </div>
      <div className="mt-5">
        <h5>
          DROIT D'OPPOSITION ET DE RETRACTATION
        </h5>
        <p>Vous avez le droit de vous opposer à la collecte de vos données personnelles et de retirer votre consentement à tout moment. Pour exercer ces droits, veuillez nous contacter à <a href="mailto:contact@eguardimmo.com" >contact@eguardimmo.com</a>.</p>
      </div>
      <div className="mt-5">
        <h5>
          L'AUTORISATION D'ACCÈS
        </h5>
        <p>En utilisant notre site/application mobile de gestion immobilière, vous consentez à nous autoriser l'accès à certaines informations nécessaires au bon fonctionnement du site/application mobile de gestion des biens immobiliers.</p>
      </div>
      <div className="mt-5">
        <h5>
          DIVULGATION DE RENSEIGNEMENTS PERSONNELS
        </h5>
        <p>Nous ne divulguons pas vos renseignements personnels à des tiers sans votre consentement, sauf si cela est nécessaire pour la gestion des biens immobiliers ou si nous sommes légalement tenus de le faire.</p>
      </div>
      <div className="mt-5">
        <h5>
          SÉCURITÉ
        </h5>
        <p>Nous mettons en place des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès non autorisé, altération, divulgation ou destruction.</p>
      </div>
      <div className="mt-5">
        <h5>
          LÉGISLATION
        </h5>
        <p>Nous nous engageons à respecter les dispositions législatives prévues par la loi 78-17 Informatique et Libertés.</p>
      </div>
      <div className="mt-5">
        <h5>
          MISES À JOUR
        </h5>
        <p>Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Les changements seront publiés sur cette page, et la date de la dernière mise à jour sera indiquée en haut de la page.</p>
      </div>
    </div>
  </div>
</div>

          </div>
        </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default Privacy;
