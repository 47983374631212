import React from "react";

const ServiceAreaTwo = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>FONCTIONNALITES</h6>
                <h2 className='title'>
                  Manier plus <span>aisément</span> vos biens immobiliers.
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/6.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    Publication d'immobilier
                  </h5>
                  <p>Affichez vos propriétés avec style sur notre application immobilière mobile.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/4.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    Recherche d'immobilier
                  </h5>
                  <p>Trouvez votre lieu idéal avec notre recherche immobilière intuitive.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/5.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    Quittances de loyer
                  </h5>
                  <p>Éditez facilement vos quittances de loyer sur votre mobile.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaTwo;
