import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/10.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>VOS ANNONCES</h6>
                <h2 className='title'>
                 Publiez vos annonces en toute mobilité
                </h2>
                <p className='content mb-4'>
                 Avec eGuardImmo, fini les papiers et classeurs. Publiez vos annonces immobilières en toute mobilité.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Rapidité: Publiez rapidement.
                      </li>
                      <li>
                        <FaCheckCircle /> Simplicité: en quelques clics.
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Mobilité: Gérez n'importe où.
                      </li>
                      <li>
                        <FaCheckCircle /> Efficacité: Annonces sans tracas.
                      </li>
                    </ul>
                  </div>
                </div>
                <p className='content'>
                  Gagnez en agilité avec notre plateforme conviviale. Publiez vos annonces immobilières où que vous soyez, en toute simplicité. Grâce à notre solution mobile intuitive, vous pouvez gérer efficacement vos biens immobiliers, assurant une visibilité maximale et des transactions fluides, garantissant ainsi une expérience de publication d'annonces sans contraintes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
