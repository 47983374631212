import React from "react";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import Privacy from "../components/Privacy";

const HomeTwo = () => {
  return (
    <>
      <NavbarTwo />
      <Privacy />
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
