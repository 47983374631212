import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
           
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>QUITTANCES DE LOYER</h6>
                <h2 className='title'>
                  Enregistrez vos locataires et éditez vos quittances de loyer
                </h2>
                <p className='content mb-4'>
                  eGuardImmo vous permet d'enregistrer vos locataires et d'éditez vos quittances de loyer sans pour autant se gèner.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Facilité d'utilisation: Simple.
                      </li>
                      <li>
                        <FaCheckCircle /> Gestion Efficace: Rapide.
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Accessibilité Totale: Mobile.
                      </li>
                      <li>
                        <FaCheckCircle /> Traçabilité Instantanée
                      </li>
                    </ul>
                  </div>
                </div>
                <p className='content'>
                 Optimisez la gestion immobilière avec notre application mobile intuitive. Enregistrez facilement les informations de vos locataires et générez rapidement des quittances de loyer. Simplifiez vos tâches administratives pour une expérience de gestion locative efficace et sans tracas, le tout à portée de main
                </p>
              </div>
            </div>
             <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/9.png'
                  alt='img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
