import React from "react";

const FooterTwo = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area footer-area-2 bg-gray mt-0'>
        
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© eGuardImmo 2023 | All Rights Reserved by <a href="https://sergeahouansinou.me" target="_blank" rel="noreferrer"  >Serge AHOUANSINOU</a> </p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='#'>Conditions d'utilisation</a>
                <a href='/privacy'>Politique de confidentialité</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  );
};

export default FooterTwo;
