import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import AboutAreaThree from "../components/AboutAreaThree";
import BannerTwo from "../components/BannerTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import ContactMain from "../components/ContactMain";
import ServiceAreaTwo from "../components/ServiceAreaTwo";

const HomeTwo = () => {
  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <BannerTwo />

      {/* About Area Two */}
      <AboutAreaTwo />

      {/* About Area Two */}
      <ServiceAreaTwo />

      {/* About Area Two */}
      <AboutAreaThree />

      <ContactMain />

      <FooterTwo />
    </>
  );
};

export default HomeTwo;
