import React, { useState } from "react";
import { FaGooglePlay } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavbarTwo = () => {
  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      <div className={"body-overlay"} id="body-overlay"></div>
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-sm">
        <div className="container nav-container">
          <div className="logo">
            <Link to="/">
              <img src="assets/img/logo.png" alt="img" width={60} />
            </Link>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <Link
              className="btn btn-border-base"
              to="https://play.google.com/store/apps/details?id=serge.ahouansinou.eguardimmo"
              target="_blank"
            >
              Play Store <FaGooglePlay />
            </Link>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;
