import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
const ContactMain = () => {
  const form = useRef();
  const [isLoading, setLoader] = useState(false)

  const sendEmail = (e) => {
    setLoader(true)
    e.preventDefault();
    // Please See Documentation for more information
    emailjs
      .sendForm(
        "service_sergeahouansinou", //YOUR_SERVICE_ID
        "template_j6j84xt", //YOUR_TEMPLATE_ID
        form.current,
        "XD2G-4i_sqHaVTFjU" //YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast.success("Massage Sent Successfully!");
            form.current[0].value = "";
            form.current[1].value = "";
            form.current[2].value = "";
            form.current[3].value = "";
             setLoader(false)
          }
        },
        (error) => {
          if (error.text !== "OK") {
            toast.success("Massage Not Sent!");
             setLoader(false)
          }
        }
      );
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      {/* contact list */}
      <div className='contact-page-list'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/13.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Contactez-nous !</h5>
                 <a href="tel:+22996504892"><h6>(+229) 965 048 92</h6></a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/14.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>E-mail</h5>
                 <a href="mailto:contact@eguardimmo.com" ><h6>contact@eguardimmo.com</h6></a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/15.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Adresse</h5>
                  <h6>Cotonou - Bénin</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='contact-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='contact-page-inner bg-gray'>
            <div className='section-title mb-4 pb-2'>
              <h2 className='title'>Laissez-nous un message </h2>
              <p className='content mb-0'>
               Vous avez une idée pour faire évoluer <b>eGuardImmo</b> ? Vous pouvez directement nous laisser un message, nous vous répondrons avec plaisir dans un très bref délais.
              </p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='name'
                      name='user_name'
                      type='text'
                      placeholder='Veuillez saisir votre Nom & Prénom(s).'
                      required
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='email'
                      name='user_email'
                      type='email'
                      placeholder='Veuillez saisir votre Email.'
                      required
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='subject'
                      name='subject'
                      type='text'
                      placeholder='Veuillez saisir le sujte de votre message.'
                      required
                    />
                  </div>
                </div>
                <div className='col-12'>
                  <div className='single-input-inner'>
                    <textarea
                      name='message'
                      id='massage'
                      cols='1'
                      rows='5'
                      placeholder='Veuillez saisir votre Message ...'
                      required
                    />
                  </div>
                </div>
                <div className='col-12 text-center'>
                  <button
                    className='btn btn-base border-radius-5'
                    type='submit'
                    disabled={isLoading}
                  >
                    ENVOYER LE MESSAGE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      
      
    </>
  );
};

export default ContactMain;
