import { BrowserRouter, Routes, Route } from "react-router-dom";


import HomeTwo from "./pages/HomeTwo";
import Privacy from "./pages/Privacy";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeTwo />} />
        <Route exact path='/privacy' element={<Privacy />} />
      </Routes>
      <ScrollToTop smooth color='#107e5a' />
    </BrowserRouter>
  );
}

export default App;
